<template>
    <el-dialog :title="title" :visible.sync="dialogVisible" top="0vh"
               @close="closeEsaioStationDetailPage" width="850px"
               :close-on-click-modal="false" center :close-on-press-escape="false">
        <el-form :model="stationInfoDto" :rules="formRules" ref="stationInfoForm" size="mini" label-width="120px">
            <el-row>
                <el-col :span="10">
                    <el-form-item label="站点名称" prop="name">
                        <el-input v-model="stationInfoDto.name" maxlength="50" />
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="装机容量(kw)" prop="capacity">
                        <el-input v-model="stationInfoDto.capacity" maxlength="10"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="归属机构" prop="orgId">
                        <doart-select-tree :width="190" :data="orgData" :checkedKeys="orgCheckedKeys"
                            @change="handleSelectOrgChangeEvent" class="form-item-class"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="站点类型" prop="type">
                        <el-select style="width: 213px;" v-model="stationInfoDto.type">
                            <el-option label="家用屋顶" value="0"></el-option>
                            <el-option label="小型工商业" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="百度经度" prop="baiduLng">
                        <el-input v-model="stationInfoDto.baiduLng" autocomplete="off" />
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="百度纬度" prop="baiduLat">
                        <el-input v-model="stationInfoDto.baiduLat" autocomplete="off" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="所属地区" prop="areaId">
                        <el-cascader ref="areaCascader" style="width: 213px;"
                            v-model="areaValue"
                            :options="areaOptions"
                            :props="areaProps"
                            clearable
                            @change="searchAreaChange">
                        </el-cascader>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-form-item label="站点地址" prop="address">
                        <el-input v-model="stationInfoDto.address" type="textarea" rows="4"
                              maxlength="250" show-word-limit/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="电站图片">
                        <el-upload action="" list-type="picture-card"
                            :auto-upload="false"
                            :on-preview="handleImagePreviewEvent"
                            :on-remove="handleImageRemoveEvent"
                            :on-change="handleImageChangeEvent"
                            :on-exceed="handleImageExceedEvent"
                            :multiple="false"
                            :limit="1"
                            :file-list="imageList">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="imageDialogVisible" :modal="false">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" size="mini" @click="saveOrModifySysUserInfo" :loading="loading">确定</el-button>
            <el-button size="mini" @click="dialogVisible = false">取消</el-button>
        </div>
    </el-dialog>
</template>
<script>
import * as stationInfoApi from "@/api/esaio/station/esaioStationInfoApi";
import global from "@/components/Global";
import message from "@/utils/message";
import DoartSelectTree from "@/components/doart-select-tree/doart-select-tree";

export default {

    name: "StationInfoDetail",
    components: {

        DoartSelectTree
    },
    data() {

        return {

            title: '',
            dialogVisible: false,
            loading: false,
            orgData: [],
            orgCheckedKeys: [],
            areaOptions: [],
            areaProps: {

                value: 'areaKey',
                label: 'areaName',
                children: 'subAreas',
                checkStrictly: false
            },
            areaValue: '',
            imageDialogVisible: false,
            dialogImageUrl: '',
            imageList: [],
            stationInfoDto: {

                id: null,
                orgId: '',
                areaId: '',
                name: '',
                address: '',
                baiduLng: '',
                baiduLat: '',
                type: '0',
                capacity: '',
                imageUrl: ''
            },
            formRules: {

                orgId: [{required: true, message: '归属机构不能为空', trigger: 'blur'}],
                areaId: [{required: true, message: '所属地区不能为空', trigger: 'blur'}],
                name: [{required: true, message: '站点名称不能为空', trigger: 'blur'}],
                capacity: [{required: true, message: '装机容量不能为空', trigger: 'blur'}]
            },
            // validateNotPassField: [],
        };
    },
    methods: {

        openAddEsaioStationInfoPage() {

            this.title = '新增站点';
            this.dialogVisible = true;
            this.queryOrgInfo();
            this.queryAreaInfo();
        },
        openModifyEsaioStationInfoPage(id) {

            this.title = '编辑站点';
            this.dialogVisible = true;
            this.stationInfoDto.id = id;
            let p1 = this.queryOrgInfo();
            let p2 = this.queryAreaInfo();
            let promiseArr = [p1, p2];
            Promise.all(promiseArr).then(() => {

                this.queryEsaioStationInfo();
            }).catch(err => {

                console.error(err);
                message.error('请求失败,请稍后再试');
            });
        },
        closeEsaioStationDetailPage() {

            this.stationInfoDto.id = null;
            this.orgData = [];
            this.orgCheckedKeys = [];
            this.areaValue = '';
            this.imageList = [];
            // this.validateNotPassField = [];
            this.$refs.stationInfoForm.resetFields();
        },
        handleSelectOrgChangeEvent(orgId) {

            this.stationInfoDto.orgId = orgId;
        },
        searchAreaChange(value) {

            let node = this.$refs.areaCascader.getCheckedNodes();
            this.stationInfoDto.areaId = node[0].data.id;
        },
        queryOrgInfo() {

            return new Promise((resolve, reject) => {

                stationInfoApi.queryCurUserEsaioStationOrgTree().then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.orgData = [res.data];
                        resolve(res.msg);
                    } else {

                        reject(res.msg);
                    }
                }).catch(err => {

                    reject('请求失败,请稍后再试');
                    console.error(err);
                });
            });
        },
        queryAreaInfo() {

            return new Promise((resolve, reject) => {

                stationInfoApi.queryEsaioStationArea().then(res => {

                    if (res.code === global.response_status_success_obj) {

                        this.areaOptions = res.data;
                        resolve(res.msg);
                    } else{

                        reject(res.msg);
                    }
                }).catch(err => {

                    reject('请求失败,请稍后再试');
                    console.error(err);
                });
            });
        },
        queryEsaioStationInfo() {

            let params = {

                id: this.stationInfoDto.id
            };
            stationInfoApi.queryEsaioStationInfoById(params).then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.stationInfoDto.orgId = res.data.orgId;
                    this.orgCheckedKeys = [res.data.orgId];
                    this.stationInfoDto.areaId = res.data.areaId;
                    this.stationInfoDto.name = res.data.name;
                    this.stationInfoDto.address = res.data.address;
                    this.stationInfoDto.baiduLng = res.data.baiduLng;
                    this.stationInfoDto.baiduLat = res.data.baiduLat;
                    this.stationInfoDto.type = res.data.type;
                    this.stationInfoDto.capacity = res.data.capacity;
                    this.stationInfoDto.imageUrl = res.data.imageUrl;
                    if (res.data.nationEn === 'china') {

                        this.areaValue = [res.data.nationEn, res.data.provEn, res.data.cityEn];
                    } else {

                        this.areaValue = [res.data.nationEn, res.data.provEn];
                    }
                    if (res.data.imageUrl !== '') {

                        this.imageList = [{

                            name: 0,
                            url: res.data.imageUrl
                        }];
                    }
                }
            });
        },
        handleImagePreviewEvent(file) {

            this.dialogImageUrl = file.url;
            this.imageDialogVisible = true;
        },
        handleImageRemoveEvent(file, fileList) {

            if (file.status === 'success') {

                this.imageList = [];
                this.stationInfoDto.imageUrl = '';
            }
        },
        handleImageChangeEvent(file, fileList) {

            this.imageList = fileList;
        },
        handleImageExceedEvent() {

            message.error("只能上传一张图片，请先删除原有头像");
        },
        saveOrModifySysUserInfo() {

            this.$refs['stationInfoForm'].validate((valid) => {

                if (valid) {

                    if (this.stationInfoDto.id != null) {

                        this.modifyEsaioStationInfo();
                    } else {

                        this.createEsaioStationInfo();
                    }
                } else {

                    return false;
                }
            });
        },
        createEsaioStationInfo() {

            let params = {

                dto: this.stationInfoDto,
                fileList: this.imageList
            }
            stationInfoApi.createEsaioStationInfo(params).then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.dialogVisible = false;
                    this.$emit('queryEsaioStationInfoList');
                    message.success('创建成功');
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        modifyEsaioStationInfo() {

            let params = {

                dto: this.stationInfoDto,
                fileList: this.imageList
            }
            stationInfoApi.modifyEsaioStationInfo(params).then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.dialogVisible = false;
                    this.$emit('queryEsaioStationInfoList');
                    message.success('修改成功');
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        }
    }
}
</script>
<style scoped lang="scss">

</style>